<template>
  <div class="container" fluid="lg">
    <h4 class="font-weight-bold mt-4">{{ $t('announcements') }}</h4>
    <div v-for="(annoucement, index) in annoucements" :key="index">
      <h5 class="mt-4 font-secondary font-weight-bold">
        {{ annoucement.year }}
      </h5>
      <hr class="my-2" />

      <div v-for="(data, i) in annoucement.data" :key="i" class="row my-2">
        <div class="col-6 font-secondary text-dark">
          {{ data.announcement_date | DateFormatter }}
        </div>

        <div class="col-6 text-primary">
          <b-button
            variant="bg-transparent text-left text-primary p-0 m-0"
            size="sm"
            :href="data.link_url"
            target="_blank"
          >
            <span v-if="$i18n.locale == 'en'">
              {{ data.title_en }}
            </span>
            <span v-else-if="$i18n.locale == 'th'">
              {{ data.title_th }}
            </span>
            <span v-else>
              {{ data.title_mm }}
            </span>
          </b-button>
        </div>
      </div>
    </div>
    <div v-if="!isBusy && annoucements.length === 0" class="col-12 text-center mt-5">
      {{ $t('no_announcements') }}
    </div>
    <loading :is-busy="isBusy"></loading>
  </div>
</template>

<script>
import CommonService from '@/api/service/CommonService'
import Loading from '@/components/Loading.vue'
export default {
  components: { Loading },
  data() {
    return {
      isBusy: true,
      annoucements: [],
    }
  },
  created() {
    this.getAnnouncement()
  },
  methods: {
    async getAnnouncement() {
      try {
        this.isBusy = true
        const res = await CommonService.getAnnouncement()
        if (res.status == 200) {
          this.annoucements = this.arrayReverseObj(res.data.data)
        }
        this.isBusy = false
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    arrayReverseObj(obj) {
      let newArray = []

      Object.keys(obj)
        .sort()
        .reverse()
        .forEach((key) => {
          newArray.push({
            year: key,
            data: this.sortArray(obj[key]),
          })
        })

      return newArray
    },
    sortArray(data) {
      return data.slice().sort(function (a, b) {
        return a.created_at > b.created_at ? -1 : 1
      })
    },
  },
}
</script>

<style></style>
